<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">财务模块</el-breadcrumb-item>
        <el-breadcrumb-item>资金流水</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="querys" ref="querys" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="流水时间范围" label-width="120px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>

      </el-row>
      <el-row>
        <el-form-item label="银行卡" prop="cardAlias" label-width="90px">
          <el-col style="width: 150px">
            <el-select v-model="cardAlias" placeholder="银行卡" clearable @change="fetchs()" style="max-width: 200px">
              <el-option v-for="dd in dict.banks" :key="dd.value" :label="dd.label" :value="dd.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button plain type="primary" icon="upload" @click="openUploadDialog()">导入银行流水</el-button>

    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">
    </static-table>
  </el-card>

  <static-dialog ref="uploadDialog" class="dialog" title="上传银行流水">
    <div class="load-dialog" style="text-align:center; max-width:500px;margin:0px auto;">
      <h4>上传前请检查文件格式是否为**.xlsx后缀(可另存之后再上传)</h4>
      <el-select v-model="cardAlias" placeholder="请选择要流水文件对应的银行卡" style="margin:15px auto; min-width: 50%;">
        <el-option v-for="dd in dict.banks" :key="dd.value" :label="dd.label" :value="dd.value">
        </el-option>
      </el-select>

      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" drag :on-change="handleChange"
        :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
        :auto-upload="true" :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>

      <!-- <el-link type="primary" @click="download()">点击下载该平台的订单导入模板.xlsx</el-link> -->

    </div>
  </static-dialog>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { fetchFlowLog, importFlowLog } from "@/api/fund";
import { uploader } from "@/api/subupload";
export default {
  name: "fundFlowLog",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        filters: [],
        columns: [
          // { type: "selection", width: "55" },
          { type: "index", label: "序号" },
          // { prop: "serialNo", label: "系统流水", width: "150", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "sourceAccountAlias", label: "账户别名", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "sourceAccountNo", label: "账户号", width: "135", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "sourceAccountName", label: "账户名", width: "120", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "sourceBankName", label: "归属银行", width: "90", sortable: true, searchable: true, showOverflowTooltip: true, },

          { prop: "revenueFee", label: "收入金额", width: "90", sortable: true, },
          { prop: "expenseFee", label: "支出金额", width: "90", sortable: true, },
          { prop: "balanceFee", label: "账户余额", width: "90", sortable: true, },

          { prop: "tradeDate", label: "交易时间", width: "140", sortable: true, },
          { prop: "targetAccountNo", label: "对方账户", width: "150", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "targetAccountName", label: "对方名称", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "targetBankName", label: "对方开户行", width: "120", searchable: true, showOverflowTooltip: true, },

          { prop: "tradeType", label: "交易方式", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "ccyCode", label: "币种", width: "50", showOverflowTooltip: true, },

          { prop: "memo", label: "摘要", width: "110", searchable: true, showOverflowTooltip: true },
          { prop: "remark", label: "备注", width: "110", searchable: true, showOverflowTooltip: true },

          { prop: "billCode", label: "关联单据", width: "145", searchable: true, showOverflowTooltip: true },

          { prop: "tradeNo", label: "银行流水", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "batchNo", label: "流水批次", width: "120", sortable: true, showOverflowTooltip: true, },

          { prop: "createDate", label: "导入时间", width: "140", sortable: true, },
          // { type: "toolbar", label: "操作", width: "80" },
        ],
      },
      pager: {
        sidx: "tradeDate", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
      selects: [],
      cardAlias: '',
      loading: false,
      fileList: [],

      dateRange: [],

      dict: {
        banks: [],
      },

      rules: {
      },
    };
  },
  methods: {
    fetchs(methodName) {
      let that = this;
      if ((typeof methodName) == 'object') methodName = null;
      this.querys.filters = [];
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      if (this.cardAlias) {
        this.querys.filters.push({
          name: "sourceAccountAlias",
          value: this.cardAlias,
          operate: 0,
        });
      }
      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "tradeDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "tradeDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });


      search.method = methodName || '';
      fetchFlowLog(search).then((res) => {
        this.pager = { ...res.data };
      })
        .catch(() => {
          that.$message({ type: "info", message: "查询失败", });
        });
    },

    openUploadDialog(row) {
      let that = this;
      that.$refs.uploadDialog.toggle();
    },

    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },

    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "FundFlowImport");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else
            this.$message.error("上传失败，请重试");
        })
        .then(() => {
          this.loading = false;
          importFlowLog(this.cardAlias, this.dataId).then(
            (res) => {
              this.fetchs();
              this.$alert(res.content, '提示');
            },
            (err) => {
              this.$alert(err.content, '提示');
            }
          );
        });

      this.fileList = [];
    },

    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },

  },
  watch: {
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);
    }
  },
  created: function () {
    this.querys.keyword = this.$util.getPageCache('keyword');

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;

    this.$kaung.dictionary("workflow", "banks").then(res => this.dict.banks = res);
    this.cardAlias = this.$route.query.cardAlias || '';

    this.fetchs();
  }
};
</script>
<style lang="scss" scoped></style>